/* cormorant-300normal - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Cormorant Light '),
    local('Cormorant-Light'),
    url('./files/cormorant-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-300italic - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Cormorant Light italic'),
    local('Cormorant-Lightitalic'),
    url('./files/cormorant-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-400normal - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Cormorant Regular '),
    local('Cormorant-Regular'),
    url('./files/cormorant-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-400italic - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Cormorant Regular italic'),
    local('Cormorant-Regularitalic'),
    url('./files/cormorant-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-500normal - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Cormorant Medium '),
    local('Cormorant-Medium'),
    url('./files/cormorant-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-500italic - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Cormorant Medium italic'),
    local('Cormorant-Mediumitalic'),
    url('./files/cormorant-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-600normal - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Cormorant SemiBold '),
    local('Cormorant-SemiBold'),
    url('./files/cormorant-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-600italic - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Cormorant SemiBold italic'),
    local('Cormorant-SemiBolditalic'),
    url('./files/cormorant-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-700normal - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Cormorant Bold '),
    local('Cormorant-Bold'),
    url('./files/cormorant-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* cormorant-700italic - latin */
@font-face {
  font-family: 'Cormorant';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Cormorant Bold italic'),
    local('Cormorant-Bolditalic'),
    url('./files/cormorant-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/cormorant-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

